var AuthenticationService, DbHelper, Exception, Exceptions, W, mediator, nodefn, utils,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Exception = require('base/lib/exception');

Exceptions = require('lib/exceptions');

DbHelper = require('base/lib/db_helper');

W = require('when');

nodefn = require('when/node/function');

utils = require('base/lib/utils');

mediator = require('mediator');

module.exports = AuthenticationService = (function() {
  function AuthenticationService() {
    this.logout = __bind(this.logout, this);
    this._localLogin = __bind(this._localLogin, this);
    this.userSessionUrl = DbHelper.getUserSessionUrl();
  }

  AuthenticationService.prototype.getLocalAccountInformation = function() {
    return gdt.storage.get('users').then((function(_this) {
      return function(value) {
        var outputIfTrue, user, users, usersWithDbs;
        users = JSON.parse(value != null ? value : '[]');
        usersWithDbs = (function() {
          var _i, _len, _results;
          _results = [];
          for (_i = 0, _len = users.length; _i < _len; _i++) {
            user = users[_i];
            outputIfTrue = function(user) {
              return function(v) {
                if (v) {
                  return user;
                }
              };
            };
            _results.push(this.udbService.isInitialized(user).then(outputIfTrue(user)));
          }
          return _results;
        }).call(_this);
        return W.map(usersWithDbs).then(function(array) {
          return _(array).compact();
        });
      };
    })(this));
  };

  AuthenticationService.prototype.register = function(firstName, lastName, email, password, tosAccepted, newsletterOptIn) {
    var fd, tosAcceptedVersion;
    tosAcceptedVersion = tosAccepted ? this.tosService.getCurrentVersion() : 0;
    fd = new FormData();
    fd.append('firstName', firstName);
    fd.append('lastName', lastName);
    fd.append('email', email);
    fd.append('password', password);
    fd.append('tosAcceptedVersion', tosAcceptedVersion);
    fd.append('newsletterOptIn', newsletterOptIn);
    return fetch(DbHelper.getRegistrationUrl(), {
      method: 'POST',
      body: fd
    }).then(function(data) {
      if (!data.ok) {
        throw data;
      }
      return data.json();
    }).then((function(_this) {
      return function(data) {
        return _this._ensureLoginIsAuthenticatedLocally(data.login).then(function() {
          return data.login;
        });
      };
    })(this))["catch"](function(err) {
      if (err instanceof window.Response) {
        if (err.status === 409) {
          throw new Exceptions.authentication.email_in_use;
        } else if (err.status === 403) {
          throw new Exceptions.authentication.registration_disabled;
        } else {
          throw new Exceptions.server_error;
        }
      }
      throw new Exceptions.no_connection;
    });
  };

  AuthenticationService.prototype._ensureLoginIsAuthenticatedLocally = function(login) {
    return gdt.storage.get('users').then(function(value) {
      var users;
      users = JSON.parse(value != null ? value : '[]');
      if (__indexOf.call(users, login) < 0) {
        users.push(login);
      }
      return gdt.storage.set('users', JSON.stringify(users));
    });
  };

  AuthenticationService.prototype.getLoginStatus = function() {
    return W($.ajax(this.userSessionUrl, {
      dataType: 'json',
      xhrFields: {
        withCredentials: true
      }
    }), function(response) {
      var login;
      login = response.login;
      return gdt.storage.set('active_user', login).then(function() {
        return {
          status: 'central',
          login: login
        };
      });
    }, function(err) {
      return gdt.storage.get('active_user').then(function(localLogin) {
        if (err.status === 401 || (localLogin == null)) {
          return {
            status: 'no'
          };
        } else {
          return {
            status: 'local',
            login: localLogin
          };
        }
      });
    });
  };

  AuthenticationService.prototype._centralLoginSuccessful = function(username) {
    return (function(_this) {
      return function(isInitialized) {
        return W.promise(function(resolve, reject, notify) {
          var _ref;
          return (_ref = _this._ensureLoginIsAuthenticatedLocally(username).then(function() {
            if (!isInitialized) {
              notify({
                action: 'initialization'
              });
            }
            return _this.udbService.initialize(username).then(null, null, notify);
          }).then(function() {
            gdt.storage.set('active_user', username);
            return {
              login: username,
              status: 'central'
            };
          }, function(error) {
            if (error) {
              utils.reportRavenError(error);
            }
            if (error instanceof Exception) {
              throw error;
            } else {
              throw new Exceptions.authentication.local_no_udb;
            }
          })).then.apply(_ref, arguments);
        });
      };
    })(this);
  };

  AuthenticationService.prototype._localLogin = function(username) {
    return gdt.storage.get('users').then((function(_this) {
      return function(value) {
        if (__indexOf.call(JSON.parse(value != null ? value : '[]'), username) < 0) {
          throw new Exceptions.authentication.local_login;
        }
        return _this.udbService.isInitialized(username).then(function(isInitialized) {
          if (!isInitialized) {
            throw new Exceptions.authentication.local_no_udb;
          }
          gdt.storage.set('active_user', username);
          return {
            login: username,
            status: 'local'
          };
        });
      };
    })(this));
  };

  AuthenticationService.prototype._getLocalLoginForEmail = function(email, loginRequestError) {
    if (loginRequestError == null) {
      loginRequestError = false;
    }
    return this.udbService.getLocalUserDocByEmail(email).then(function(userDoc) {
      var errKey;
      if (userDoc) {
        return userDoc.name;
      } else {
        errKey = loginRequestError ? 'unable_to_get_login_for_email' : 'no_login_for_email_local';
        throw new Exceptions.authentication[errKey];
      }
    });
  };

  AuthenticationService.prototype.login = function(email, password) {
    return W($.ajax(this.userSessionUrl, {
      type: 'POST',
      dataType: 'json',
      data: {
        email: email,
        password: password
      },
      xhrFields: {
        withCredentials: true
      }
    })).then((function(_this) {
      return function(_arg) {
        var hasAccess, latestSubscription, login;
        login = _arg.login, hasAccess = _arg.hasAccess, latestSubscription = _arg.latestSubscription;
        return _this.udbService.isInitialized(login).then(_this._centralLoginSuccessful(login));
      };
    })(this), (function(_this) {
      return function(xhr) {
        if (xhr.status === 401) {
          throw new Exceptions.authentication.server;
        } else if (xhr.status === 404) {
          throw new Exceptions.authentication.user_not_found;
        }
        return _this._getLocalLoginForEmail(email, xhr.status !== 0).then(_this._localLogin);
      };
    })(this));
  };

  AuthenticationService.prototype.logout = function() {
    var localLogout;
    localLogout = function() {
      return gdt.storage.remove('active_user');
    };
    return W($.ajax(this.userSessionUrl, {
      type: 'DELETE',
      xhrFields: {
        withCredentials: true
      }
    }), localLogout, localLogout);
  };

  AuthenticationService.prototype._resetPassword = function(data) {
    var url;
    url = "" + (DbHelper.getBackendUrl()) + "/accounts/request-password-reset";
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(function(response) {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })["catch"](function(err) {
      if (err instanceof window.Response) {
        if (err.status === 404) {
          throw new Exceptions.authentication.user_not_found;
        } else if (err.status >= 500) {
          throw new Exceptions.server_error;
        }
      }
      throw new Exceptions.no_connection;
    });
  };

  AuthenticationService.prototype.resetPasswordForEmail = function(email) {
    return this._resetPassword({
      email: email
    });
  };

  AuthenticationService.prototype.changePersonalData = function(data) {
    var url;
    url = "" + (DbHelper.getBackendUrl()) + "/accounts/" + (mediator.user.getLogin());
    return fetch(url, {
      type: 'PATCH',
      body: JSON.stringify(data),
      xhrFields: {
        withCredentials: true
      }
    }).then(function(response) {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })["catch"](function(err) {
      if (err instanceof window.Response) {
        if (err.status === 403) {
          throw new Exceptions.authentication.forbidden;
        } else {
          throw new Exceptions.server_error;
        }
      }
      throw new Exceptions.no_connection;
    });
  };

  return AuthenticationService;

})();
